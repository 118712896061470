<template>
  <div class="reset_container">
    <div class="reset_form">
      <div class="reset_form_container">
        <div class="reset">
          <div class="reset_pass_form">
            <div class="reset_label">{{ $t('Reset_password') }}</div>
            <div class="reset_input_pass_container">
              <input :type="showPass ? 'text' : 'password'" v-model="password" class="reset_input_pass"
                     :placeholder="$t('New_Password')"/>
              <div class="reset_input_pass_open" v-show="showPass" @click="showPass = false"/>
              <div class="reset_input_pass_close" v-show="!showPass" @click="showPass = true"/>
              <span v-if="$v.password.$dirty && $v.password.$invalid"
                    class="text-danger">{{ $t('Password_is_required!_At_least_8_characters') }}.</span>
            </div>
            <div class="reset_input_pass_container">
              <input :type="showNewPass ? 'text' : 'password'" v-model="passwordNew" class="reset_input_pass"
                     :placeholder="$t('Repeat_password')"/>
              <div class="reset_input_pass_open" v-show="showNewPass" @click="showNewPass = false"/>
              <div class="reset_input_pass_close" v-show="!showNewPass" @click="showNewPass = true"/>
              <span v-if="this.password !== this.passwordNew" class="text-danger">{{
                  $t('Passwords_must_match')
                }}!</span>
            </div>
            <div class="reset_btn_next" @click="resetPass">
              <span>{{ $t('Save') }}</span>
            </div>
          </div>

          <div class="profile-inputs-column-mobile profile-margin">
            <div class="password-required-name">{{ $t('Your_password_must') }}:</div>

            <div class="recommendation-block">
              {{ $t('Consist_of_more_than_8_characters') }}
            </div>
            <div class="recommendation-block">
              {{ $t('Contain_at_least_one_capital_letter') }}
            </div>
            <div class="recommendation-block">
              {{ $t('Contain_at_least_one_number') }}
            </div>
            <div class="recommendation-block">
              {{ $t('Contain_special_characters') }}
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="signin_logo">
      <div class="signin_logo_container"></div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import LoginForm from '@/components/LoginForm';
import {minLength, required} from "vuelidate/lib/validators";

export default {
  name: 'Login',
  components: {
    LoginForm
  },
  data() {
    return {
      showPass: false,
      password: "",
      showNewPass: false,
      passwordNew: "",
    }
  },
  mounted() {
    localStorage.setItem('token', this.$route.query.token)
    this.$http.post(API_ROOT + `/api/auth/email/confirm`, {token: this.$route.query.token})
      .then((res) => {
        localStorage.setItem('has_email', true)
        localStorage.setItem('token', res.body.token)
        Vue.toastr({
          message: 'Success',
          description: res.body.message,
          type: 'success'
        })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {

      })

  },
  methods: {
    resetPass() {
      let postData = {
        new_password: this.password,
        new_password_repeat: this.passwordNew,
        verification_token: this.$route.query.token
      }

      this.$v.$touch()
      if (this.$v.password.$invalid) return

      // let headers = {}
      // headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')

      this.$http.post(API_ROOT + `/api/user/update-password-via-token`, postData)
      // this.$http.post(API_ROOT + `/api/user/update-password`, postData, { headers })
        .then((res) => {
          localStorage.setItem('user', JSON.stringify(res.body))
          if (res.body.token)
            localStorage.setItem('token', res.body.token)
          window.location = "/profile";
          console.log(res)
          Vue.toastr({
            message: 'Success',
            description: res.body.message,
            type: 'success'
          })
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {

        })
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(8)
    },
    passwordNew: {
      required,
      minLength: minLength(8)
    }
  },

}
</script>
<style>
.reset_form {
  margin-left: 6rem !important;
  justify-content: left !important;
  width: 100% !important;
}

.reset {
  flex-direction: row !important;
}

@media (max-width: 1600px) {
  .reset_form {
    width: 100%;
  }
}

@media (max-width: 1292px) {
  .signin_logo {
    display: none !important;
  }

  .reset_form {
    justify-content: center !important;
    margin-left: 0 !important;
    margin: auto !important;
  }
}

@media (max-width: 900px) {
  .recommendation-block {
    max-width: unset !important;
  }

  .profile-inputs-column-mobile {
    width: 100% !important;
  }

  .reset {
    width: 100% !important;
    flex-direction: column !important;
  }

  .reset_form {
    margin: auto 2rem !important;
  }

  .reset_form_container {
    width: 100%;
  }

  .reset_pass_form {
    width: 100% !important;
  }
}
</style>
